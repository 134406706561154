var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("All-State - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Applications","active":""}})]}},{key:"actions",fn:function(){return [(_vm.canOverrideSettings || (_vm.setting.enabled && _vm.isCreatedAtInCurrentYear(new Date().toISOString())))?[_c('can',{attrs:{"do":"create","on":"all-state-applications"}},[_c('b-button',{staticClass:"d-inline-flex align-items-center",attrs:{"variant":"primary","size":"sm","to":{ name: 'all-state-application-create' }}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"fa-solid fa-plus"}}),_vm._v(" Create ")],1)],1)]:_vm._e()]},proxy:true},{key:"dropdown-options",fn:function(ref){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]}},{key:"tour",fn:function(ref){
var tourName = ref.tourName;
return [(_vm.$refs['table-layout'])?_c('tour',{attrs:{"name":tourName,"steps":_vm.$refs['table-layout'].tour.steps,"callbacks":_vm.$refs['table-layout'].tour.callbacks}}):_vm._e()]}},{key:"content",fn:function(ref){
var state = ref.state;
return [[(!_vm.setting.enabled && !_vm.canOverrideSettings)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('p',[_c('b-icon-info-circle',{staticClass:"mr-25"}),_vm._v(" New Applications are currently disabled. ")],1),(_vm.setting.message && _vm.setting.message.enabled)?_c('small',[_vm._v(_vm._s(_vm.setting.message.text))]):_c('small',[_vm._v(" If you believe you should still be able to create a new application, please "),_c('b-link',{attrs:{"to":{ name: 'support' }}},[_c('u',[_vm._v("contact us")])]),_vm._v(" requesting temporary access. ")],1)]):_vm._e()],_c('table-layout',{ref:"table-layout",attrs:{"items":_vm.table.items,"loading":_vm.table.loading,"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: true },"sorting":{ by: 'createdAt', desc: true },"subscriptions":_vm.table.subscriptions,"is-searchable":true,"cache-exired":_vm.$store.getters[((_vm.$route.name) + "/isExpired")],"cache-time":_vm.$store.state[_vm.$route.name].createdAt,"func-refresh":_vm.refresh,"export-exclude-fields":[
                      'id',
                      'applicationFestivalId',
                      'applicationFormId',
                      'applicationInstrumentId',
                      'festival.id',
                      'form.id',
                      'form.instruments.items.length',
                      'instrument.id',
                      'student.id',
                      'teacher.id' ]},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.table.loading, desc: 'Loading Applications', loaded: _vm.table.loaded },
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Festival","label-for":"festival-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"festival-input","options":_vm.optionsFestivals,"label":"name","loading":_vm.options.festivals.loading,"reduce":function (option) { return option.id; },"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var id = ref.id;
        var name = ref.name;
        var site = ref.site;
return [_c('span',{staticClass:"d-block"},[_vm._v("Festival "+_vm._s(name))]),_c('small',[_vm._v(_vm._s(site))])]}},{key:"selected-option",fn:function(ref){
        var id = ref.id;
        var name = ref.name;
        var site = ref.site;
return [_c('span',{staticClass:"d-block"},[_vm._v("Festival "+_vm._s(name)+" - "+_vm._s(site))])]}}],null,true),model:{value:(_vm.table.filters.festival.value),callback:function ($$v) {_vm.$set(_vm.table.filters.festival, "value", $$v)},expression:"table.filters.festival.value"}})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Form","label-for":"form-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"form-input","options":_vm.optionsForms,"label":"name","loading":_vm.options.forms.loading,"reduce":function (option) { return option.id; },"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.form.value),callback:function ($$v) {_vm.$set(_vm.table.filters.form, "value", $$v)},expression:"table.filters.form.value"}})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument-input"}},[_c('v-select',{staticClass:"w-100",attrs:{"input-id":"instrument-input","options":_vm.optionsInstruments,"label":"name","loading":_vm.options.instruments.loading,"reduce":function (option) { return option.id; },"select-on-tab":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1)],1)]},proxy:true},{key:"cell(festival.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.festival)?_c('div',[_vm._v(" "+_vm._s(data.item.festival.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Festival ")])]}},{key:"cell(form.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.form)?_c('div',[_vm._v(" "+_vm._s(data.item.form.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Form ")])]}},{key:"cell(instrument.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.instrument)?_c('div',[_vm._v(" "+_vm._s(data.item.instrument.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Instrument ")])]}},{key:"cell(student.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.student && data.item.student.name)?_c('div',[_vm._v(" "+_vm._s(data.item.student.name.full)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Student ")])]}},{key:"cell(teacher.name)",fn:function(ref){
        var data = ref.data;
return [(data.item.teacher && data.item.teacher.name)?_c('div',[_vm._v(" "+_vm._s(data.item.teacher.name.full)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Teacher ")])]}},{key:"cell(student.school.name.legal)",fn:function(ref){
        var data = ref.data;
return [(data.item.student.school && data.item.student.school.name)?_c('div',[_vm._v(" "+_vm._s(data.item.student.school.name.legal)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No School ")])]}},{key:"cell(createdAt)",fn:function(ref){
        var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.createdAt,"no-text":""}})]}},{key:"cell(updatedAt)",fn:function(ref){
        var data = ref.data;
return [_c('last-modified',{attrs:{"date":data.item.updatedAt,"no-text":""}})]}},{key:"cell(row-options)",fn:function(ref){
        var data = ref.data;
return [_c('b-dropdown-item',{staticClass:"table-row-option-view",attrs:{"to":{ name: 'all-state-application', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View")])],1),_c('can',{attrs:{"do":"create","on":"all-state-applications"}},[_c('b-dropdown-item',{staticClass:"table-row-option-clone",attrs:{"to":{ name: 'all-state-application-create', query: { clone: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Clone")])],1)],1),_c('can',{attrs:{"do":"delete","on":"all-state-applications"}},[_c('b-dropdown-divider'),_c('b-dropdown-item',{staticClass:"table-row-option-delete",on:{"click":function($event){return _vm.$refs.layout.confirmDelete(data.item, _vm.deleteApplication, _vm.cascadeConfirmDeleteOptions)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}],null,true)})]}},{key:"debug",fn:function(){return [_c('debug',{attrs:{"collapsed":""}},[_vm._v(_vm._s(_vm.table.items.slice(0, 10)))])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }