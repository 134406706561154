<template>
  <page-layout ref="layout" @refresh="refresh">

    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Applications" active/>
    </template>

    <template #actions>
      <template v-if="canOverrideSettings || (setting.enabled && isCreatedAtInCurrentYear(new Date().toISOString()))">
        <can do="create" on="all-state-applications">
          <b-button variant="primary" size="sm" :to="{ name: 'all-state-application-create' }" class="d-inline-flex align-items-center">
            <font-awesome-icon icon="fa-solid fa-plus" class="mr-50" /> Create
          </b-button>
        </can>
      </template>
    </template>

    <template #dropdown-options="{ }">
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #tour="{ tourName }">
      <tour v-if="$refs['table-layout']" :name="tourName" :steps="$refs['table-layout'].tour.steps" :callbacks="$refs['table-layout'].tour.callbacks"/>
    </template>

    <template #content="{ state }">
      <template>
        <b-alert v-if="!setting.enabled && !canOverrideSettings" show variant="danger">
          <p>
            <b-icon-info-circle class="mr-25"/>
            New Applications are currently disabled.
          </p>
          <small v-if="setting.message && setting.message.enabled">{{ setting.message.text }}</small>
          <small v-else>
            If you believe you should still be able to create a new application, please
            <b-link :to="{ name: 'support' }"><u>contact us</u></b-link> requesting temporary access.
          </small>
        </b-alert>
      </template>

      <table-layout ref="table-layout"
                    :items="table.items"
                    :loading="table.loading"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                    :sorting="{ by: 'createdAt', desc: true }"
                    :subscriptions="table.subscriptions"
                    :is-searchable="true"
                    :cache-exired="$store.getters[`${$route.name}/isExpired`]"
                    :cache-time="$store.state[$route.name].createdAt"
                    :func-refresh="refresh"
                    :export-exclude-fields="[
                        'id',
                        'applicationFestivalId',
                        'applicationFormId',
                        'applicationInstrumentId',
                        'festival.id',
                        'form.id',
                        'form.instruments.items.length',
                        'instrument.id',
                        'student.id',
                        'teacher.id',
                    ]"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Applications', loaded: table.loaded },
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

        <!-- Filters -->
        <template #filters>
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group label="Festival" label-for="festival-input">
                <v-select v-model="table.filters.festival.value"
                          input-id="festival-input"
                          :options="optionsFestivals" label="name"
                          :loading="options.festivals.loading"
                          :reduce="option => option.id"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100">
                  <template #option="{ id, name, site }">
                    <span class="d-block">Festival {{ name }}</span>
                    <small>{{ site }}</small>
                  </template>
                  <template #selected-option="{ id, name, site }">
                    <span class="d-block">Festival {{ name }} - {{ site }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group label="Form" label-for="form-input">
                <v-select v-model="table.filters.form.value"
                          input-id="form-input"
                          :options="optionsForms" label="name"
                          :loading="options.forms.loading"
                          :reduce="option => option.id"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group label="Instrument" label-for="instrument-input">
                <v-select v-model="table.filters.instrument.value"
                          input-id="instrument-input"
                          :options="optionsInstruments" label="name"
                          :loading="options.instruments.loading"
                          :reduce="option => option.id"
                          :select-on-tab="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>


        <template #cell(festival.name)="{data}">
          <div v-if="data.item.festival">
            {{ data.item.festival.name }}
          </div>
          <div v-else class="text-danger">
            No Festival
          </div>
        </template>

        <template #cell(form.name)="{data}">
          <div v-if="data.item.form">
            {{ data.item.form.name }}
          </div>
          <div v-else class="text-danger">
            No Form
          </div>
        </template>

        <template #cell(instrument.name)="{data}">
          <div v-if="data.item.instrument">
            {{ data.item.instrument.name }}
          </div>
          <div v-else class="text-danger">
            No Instrument
          </div>
        </template>

        <template #cell(student.name)="{data}">
          <div v-if="data.item.student && data.item.student.name">
            {{ data.item.student.name.full }}
          </div>
          <div v-else class="text-danger">
            No Student
          </div>
        </template>

        <template #cell(teacher.name)="{data}">
          <div v-if="data.item.teacher && data.item.teacher.name">
            {{ data.item.teacher.name.full }}
          </div>
          <div v-else class="text-danger">
            No Teacher
          </div>
        </template>

        <template #cell(student.school.name.legal)="{data}">
          <div v-if="data.item.student.school && data.item.student.school.name">
            {{ data.item.student.school.name.legal }}
          </div>
          <div v-else class="text-danger">
            No School
          </div>
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(createdAt)="{data}">
         <last-modified :date="data.item.createdAt" no-text />
        </template>

        <!-- Column: State - Enabled -->
        <template #cell(updatedAt)="{data}">
          <last-modified :date="data.item.updatedAt" no-text />
        </template>

        <!-- Column: Actions -->
        <template #cell(row-options)="{data}">
          <b-dropdown-item :to="{ name: 'all-state-application', params: { id: data.item.id } }"
                           class="table-row-option-view">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>
          <can do="create" on="all-state-applications">
            <b-dropdown-item class="table-row-option-clone"
                             :to="{ name: 'all-state-application-create', query: { clone: data.item.id } }">
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">Clone</span>
            </b-dropdown-item>
          </can>
          <can do="delete" on="all-state-applications">
            <b-dropdown-divider/>
            <b-dropdown-item class="table-row-option-delete"
                             @click="$refs.layout.confirmDelete(data.item, deleteApplication, cascadeConfirmDeleteOptions)">

              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </can>
        </template>
      </table-layout>
    </template>

    <template #debug>
      <debug collapsed>{{ table.items.slice(0, 10) }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import LastModified from '@/components/LastModified.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Tour from '@/components/Tour.vue';
import Fuse from 'fuse.js';
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  listApplications,
  listApplicationsByTeacher,
  listFestivals,
  listForms,
  listSchools,
  listStudents,
  getApplication,
  onCreateApplication,
  onDeleteApplication
} from './queries/applications';
import { cascadeDeleteApplication, cascadeConfirmDeleteOptions} from '@/graphql/cascade/application';
import settingsMixin from '@/mixins/settings.mixin';
import {getSetting, getUser, onUpdateSetting} from '@/graphql/queries/application';

export default {
  name: 'Applications',
  components: {
    Tour,
    OverlayLoading,
    LastModified,
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [ avatar, notify, print, settingsMixin ],
  data() {
    return {
      user: null,
      setting: { enabled: false },
      settingSubscription: { onUpdate: null },
      table: {
        fields: [
          {
            key: 'festival.name',
            label: 'Festival',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'form.name',
            label: 'Form',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'teacher.name',
            subKeys: ['teacher.name.first', 'teacher.name.last', 'teacher.name.full'],
            label: 'Teacher',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'student.name',
            subKeys: ['student.name.first', 'student.name.last', 'student.name.full'],
            label: 'Student',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.school.name.legal',
            label: 'School',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'updatedAt',
            label: 'Last Modified',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          festival: { key: 'applicationFestivalId', value: null },
          form: { key: 'applicationFormId', value: null },
          instrument: { key: 'applicationInstrumentId', value: null },
        },
        items: [],
        loading: true,
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      options: {
        festivals: {
          items: [],
          loading: false,
          loaded: false
        },
        forms: {
          items: [],
          loading: false,
          loaded: false
        },
        instruments: {
          items: [],
          loading: false,
          loaded: false
        },
        students: {
          items: [],
          loading: false,
          loaded: false
        },
        schools: {
          items: [],
          loading: false,
          loaded: false
        }
      },
      icon: 'fas fa-music',
      debug: {},
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    canOverrideSettings() {
      return this.setting?.enabled
          || this.setting?.override?.groups?.some(group => this.user.groups.includes(group))
          || this.setting?.override?.users?.some(user => user.id === this.user.id)
          || this.$can('manage')
    },
    optionsFestivals() {
      if(!this.options.festivals.loaded) return []
      if(this.user.groups.includes('Teacher')) {
        return this.options.festivals.items.filter(festival => this.table.items.some(item => item.applicationFestivalId === festival.id))
      }
      return this.options.festivals.items
    },
    optionsForms() {
      if(!this.options.forms.loaded) return []
      if(this.user.groups.includes('Teacher')) {
        return this.options.forms.items.filter(form => this.table.items.some(item => item.applicationFormId === form.id) )
      }
      return this.options.forms.items
    },
    optionsInstruments() {
      if(!this.options.instruments.loaded) return []
      if(this.user.groups.includes('Teacher')) {
        return this.options.instruments.items.filter(instrument => this.table.items.some(item => item.applicationInstrumentId === instrument.id) )
      }
      return this.options.instruments.items
    }
  },
  async mounted() {
    this.$refs.layout.state.loading = false
    await this.getCurrentUser()
    await this.getSetting()
    await Promise.all([
      this.listFestivals(),
      this.listForms(),
      this.listSchools()
    ]);

    await this.listStudents();
    await this.listApplications();

    this.onUpdateSetting();
    this.onCreateApplication();
    this.onDeleteApplication();
  },
  beforeDestroy() {
    if(this.settingSubscription?.onUpdate) {
      this.settingSubscription.onUpdate.unsubscribe()
    }
  },


  methods: {
    /* eslint-disable no-await-in-loop */
    async refresh() {
      this.options.festivals.loading = true
      this.options.festivals.loaded = false
      this.options.festivals.items = []

      this.table.loaded = 0
      this.table.paging.total = 0
      this.table.loading = true

      await Promise.all([
        await this.listFestivals(),
        await this.listStudents()
      ]);
      await this.listApplications()
    },
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
      this.user.schools.items.sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
    },
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'application' }));
      const setting = response.data.getSetting
      if(setting) {
        this.setting = JSON.parse(setting.value)
      }
      this.loading = false
    },


    /** Table Data **/
    async listFestivals(nextToken, pagedFestivals) {
      this.options.festivals.loading = true
      const festivals = pagedFestivals || []

      const input = {
        limit: 100,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
                this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }
      const response = await API.graphql(graphqlOperation(listFestivals, input));
      festivals.push(...response.data.listFestivals.items);

      if(response.data.listFestivals.nextToken) {
        await this.listFestivals(response.data.listFestivals.nextToken, festivals)
      }
      else {
        this.options.festivals.items = festivals.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        if(!this.options.festivals.items.some(item => item.id === this.table.filters.festival.value)) {
          this.table.filters.festival.value = null
        }
        this.options.festivals.loaded = true
        this.options.festivals.loading = false
      }
    },
    async listForms(nextToken, pagedFestivals) {
      this.options.forms.loading = true
      this.options.instruments.loading = true

      const forms = pagedFestivals || []
      const response = await API.graphql(graphqlOperation(listForms, { limit: 100, nextToken: nextToken }));
      forms.push(...response.data.listForms.items);

      if(response.data.listForms.nextToken) {
        await this.listForms(response.data.listForms.nextToken, forms)
      }
      else {
        this.options.forms.items = forms.sort((a, b) => a.name.localeCompare(b.name))
        this.options.forms.loading = false
        this.options.forms.loaded = true

        forms.forEach(form => {
          form.instruments.items.forEach(instrument => this.options.instruments.items.push(instrument))
        })

        this.options.instruments.items.sort((a, b) => a.name.localeCompare(b.name))
        this.options.instruments.loading = false
        this.options.instruments.loaded = true
      }
    },
    async listSchools() {
      //console.time('listSchools')
      this.options.schools.loading = true;
      let nextToken = null;
      const schools = [];

      do {
        const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
        schools.push(...response.data.listSchools.items);
        nextToken = response.data.listSchools.nextToken;
      }
      while (nextToken);

      this.options.schools.items = schools;
      this.options.schools.loading = false;
      this.options.schools.loaded = true;
      //console.timeEnd('listSchools')
    },
    async listStudents() {
      //console.time('listStudents')
      let nextToken = null;
      const students = [];
      const inputCommon = {
        limit: 1000,
      };

      const isTeacher = this.user.groups.includes('Teacher');
      const schoolsMap = new Map(this.options.schools.items.map(item => [item.id, item]));

      do {
        let response;
        if (isTeacher) {
          const inputTeacher = {
            ...inputCommon,
            filter: {
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            },
            nextToken: nextToken
          };

          /*if(this.user.schoolIds.length) {
            inputTeacher.filter.or = [ ]
            this.user.schoolIds.forEach(id => {
              inputTeacher.filter.or.push({ schoolID: { eq: id } })
            })
          }*/

          response = await API.graphql(graphqlOperation(listStudents, inputTeacher));
        }
        else {
          const inputOthers = {
            ...inputCommon,
            filter: {
              createdAt: {
                between: [
                  this.settingsStore.app.current.year.start,
                  this.settingsStore.app.current.year.end
                ]
              }
            },
            nextToken: nextToken
          };
          response = await API.graphql(graphqlOperation(listStudents, inputOthers));
        }
        students.push(...response.data.listStudents.items);
        nextToken = response.data.listStudents.nextToken;
        this.options.students.loaded = students.length;
      }
      while (nextToken);

      /** Post-processing **/
      students.forEach(student => {
        student.school = schoolsMap.get(student.schoolID);
        student.name.full = `${student.name?.first} ${student.name?.last}`;
      });


      /** Set the Options **/
      this.options.students.items = students
      this.options.students.loading = false

      //console.timeEnd('listStudents')
    },
    async listApplications() {
      //console.time('listApplications')
      let nextToken = null;
      const applications = [];
      const inputCommon = {
        limit: 1000,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end]
          }
        },
        includeTeacher: this.table.fields.find(field => field.key === 'teacher.name')?.visible || false,
      };

      const isTeacher = this.user.groups.includes('Teacher');
      const festivalMap = new Map(this.options.festivals.items.map(item => [item.id, item]));
      const formMap = new Map(this.options.forms.items.map(item => [item.id, item]));
      const instrumentMap = new Map(this.options.instruments.items.map(item => [item.id, item]));
      const studentsMap = new Map(this.options.students.items.map(item => [item.id, item]));

      /* eslint-disable no-await-in-loop */
      do {
        let response;
        if (isTeacher) {
          const inputTeacher = {
            ...inputCommon,
            teacherID: this.user.id,
            limit: 100,
            nextToken: nextToken
          };
          response = await API.graphql(graphqlOperation(listApplicationsByTeacher, inputTeacher));
          applications.push(...response.data.listApplicationsByTeacher.items);
          nextToken = response.data.listApplicationsByTeacher.nextToken;
        }
        else {
          const inputOthers = {
            ...inputCommon,
            nextToken: nextToken
          };
          response = await API.graphql(graphqlOperation(listApplications, inputOthers));
          applications.push(...response.data.listApplications.items);
          nextToken = response.data.listApplications.nextToken;
        }
        this.table.loaded = applications.length;
      }
      while (nextToken);
      /* eslint-enable no-await-in-loop */

      /** Post-processing **/
      applications.forEach(application => {
        application.festival = festivalMap.get(application.applicationFestivalId);
        application.form = formMap.get(application.applicationFormId);
        application.instrument = instrumentMap.get(application.applicationInstrumentId);
        application.student = studentsMap.get(application.studentApplicationsId);
        if(application.teacher) {
          application.teacher.name.full = `${application.teacher.name?.first} ${application.teacher.name?.last}`
        }
      })

      /** Set the Table **/
      this.table.items = applications
      this.table.loading = false;
      /*await this.$store.dispatch(`${this.$route.name}/setItems`, {
        key: this.$route.name,
        items: this.table.items
      });*/
      //console.timeEnd('listApplications')
    },

    async deleteApplication(application, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteApplication(application.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== application.id);
        this.notify({ title: 'Success', text: 'Application was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Application failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteApplication,

    /** Subscriptions **/
    onCreateApplication() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateApplication)).subscribe(async (sourceData) => {
        const createdApp = sourceData.value.data.onCreateApplication
        if (createdApp) {
          const response = await API.graphql(graphqlOperation(getApplication, {id: createdApp.id}));
          const application = response.data.getApplication

          if(this.user.groups.includes('Teacher')) {
            if(application?.teacher?.id === this.user?.id) {
              this.table.loading = true
              this.table.items = [application, ...this.table.items];
              await this.$store.dispatch(`${this.$route.name}/setItems`, this.table.items)
            }
          }
          else {
            this.table.loading = true
            this.table.items = [application, ...this.table.items];
            await this.$store.dispatch(`${this.$route.name}/setItems`, this.table.items)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteApplication() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteApplication)).subscribe((sourceData) => {
        this.table.loading = true
        const application = sourceData.value.data.onDeleteApplication
        if (application) {
          this.table.items = this.table.items.filter(item => item.id !== application.id);
          this.table.loading = false
          this.$store.dispatch(`${this.$route.name}/setItems`, this.table.items)
        }
      });
    },
    onUpdateSetting() {
      this.settingSubscription.onUpdate = API.graphql(graphqlOperation(onUpdateSetting)).subscribe((sourceData) => {
        const setting = sourceData.value.data.onUpdateSetting
        if (setting?.key === 'application') {
          this.syncNotification()
          this.setting = JSON.parse(setting.value)
        }
      });
    },

    /** Utils **/
    /* eslint-enable no-await-in-loop */
  }
}
</script>

<style lang="scss">

</style>
